<template>
  <div id="loading-bg">
    <div class="loading-logo">
      <img
        class="loading-img"
        src="../../../public/default.png"
        alt="Logo"
      >
    </div>
    <div class="loading">
      <div class="effect-1 effects" />
      <div class="effect-2 effects" />
      <div class="effect-3 effects" />
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.handleLoading()
  },
  methods: {
    async handleLoading() {
      try {
        const { data } = await this.$api.getUser()
        if (localStorage.getItem('access_token') || data) {
          this.$router.push('/agent/pre')
        } else {
          this.$router.push('/login')
        }
      } catch (error) {
        this.$router.push('/login')
        console.log('error', error)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
